import React from "react"
import "./DeveloperCard.scss"
import { Link } from "gatsby"
import { stripHTML } from "@lib/utils"
import noImg from "@images/noImage.png"
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon"
import { developersURL } from "@lib/urls"
import GGFXImage from "@components/elements/GGFXImage"

const DeveloperCard = ({ developer }) => {
  if (!developer) return null

  const { logo, name, description, image, slug, ggfx_results, strapi_id  } = developer

  const formattedDescription = stripHTML(description?.data?.description)

  let ImageSection = null

  const url = `${developersURL + slug}/`
   if (logo && image) {
    ImageSection = (
      <div className="img-section">
        {/* <img src={image?.url} alt={`developer-${name}-image`} /> */}
        <GGFXImage
                      ImageSrc={image}
                      altText={`developer-${name}-image`}
                      imagetransforms={ggfx_results}
                      renderer="srcSet"
                      imagename="developer.image.slider_image"
                      strapiID={strapi_id}
                      lazyLoading
                    />
        {logo && (
          <div className="logo-section">
            {/* <img src={logo.url} alt={`developer-${name}-image`} /> */}
            <GGFXImage
                      ImageSrc={logo}
                      altText={`developer-${name}-image`}
                      imagetransforms={ggfx_results}
                      renderer="srcSet"
                      imagename="developer.logo.slider_logo_mini"
                      strapiID={strapi_id}
                      lazyLoading
                    />
          </div>
        )}
      </div>
    )
  } else if (!image) {
    ImageSection = (
      <div className="img-section logo-as-img">
        {/* <img src={logo?.url} alt={`developer-${name}-image`} /> */}
        <GGFXImage
                      ImageSrc={logo}
                      altText={`developer-${name}-image`}
                      imagetransforms={ggfx_results}
                      renderer="srcSet"
                      imagename="developer.logo.slider_logo"
                      strapiID={strapi_id}
                      lazyLoading
                    />
      </div>
    )
  } else if (!logo) {
    ImageSection = (
      <div className="img-section">
        {/* <img src={image?.url} alt={`developer-${name}-image`} /> */}
        <GGFXImage
                      ImageSrc={image}
                      altText={`developer-${name}-image`}
                      imagetransforms={ggfx_results}
                      renderer="srcSet"
                      imagename="developer.image.slider_image"
                      strapiID={strapi_id}
                      lazyLoading
                    />
      </div>
    )
  } else {
    ImageSection = (
      <div className="img-section">
        <img src={noImg} alt={`developer-${name}-image`} />
      </div>
    )
  }

  return (
    <div className="developer-card">
      <Link to={url} className="img-section-wrap img-zoom">
        {ImageSection}
      </Link>
      <Link to={url} className="name">
        <span>{name}</span>
        <ArrowUpRightIcon />
      </Link>
      {formattedDescription && (
        <p className="description">{formattedDescription}</p>
      )}
    </div>
  )
}

export default DeveloperCard
