import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import "./DevelopersListing.scss"
import useDevelopers from "@hooks/dataHooks/useDevelopers"
import DeveloperCard from "@components/DeveloperCard/DeveloperCard"
import Fuse from "fuse.js"
import Pagination from "@components/Pagination/Pagination"
import usePagination from "@hooks/usePagination"
import { isEmpty } from "lodash"
import SearchIcon from "@components/icons/SearchIcon"
import { useSessionStorage } from "@hooks/useSessionStorage"

const DevelopersListing = () => {
  const developers = useDevelopers()
  const [developersList, setDevelopersList] = React.useState(developers)

  const [searchText, setSearchText] = React.useState("")
  const [currentPage, setCurrentPage] = useSessionStorage("developer_page", 1)
  const paginationProps = usePagination({
    items: developersList,
    itemsPerPage: 24,
    customCurrentPage: currentPage,
    customSetCurrentPage: setCurrentPage,
  })

  const handleSearch = () => {
    if (searchText === "") return setDevelopersList(developers)

    const fuse = new Fuse(developers, {
      keys: ["name"],
      ignoreLocation: true,
      threshold: 0.4,
    })

    const result = fuse.search(searchText).map((item) => item.item)
    paginationProps.setCurrentPage(1)

    return setDevelopersList(result)
  }

  // search on every change in search text
  useEffect(() => {
    handleSearch()
  }, [searchText])

  return (
    <div className="developer-listing-wrap listing-wrap">
      <Container className="developer-listing-container">
        <Container className="category-section search-section-wrap">
          <div className="search-section">
            <div className="search-input-wrap">
              <input
                type="text"
                placeholder="Search Developers"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <button className="button button-orange" onClick={handleSearch}>
              <span>Search</span>
              <SearchIcon color="white" />
            </button>
          </div>
        </Container>
        <div className="developer-listing-section">
          {isEmpty(paginationProps?.currentItems) ? (
            <p className="no-results">No results found</p>
          ) : (
            paginationProps?.currentItems?.map((developer) => (
              <DeveloperCard key={developer.id} developer={developer} />
            ))
          )}
        </div>
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default DevelopersListing
