import React from "react"
import "./AreaguideCard.scss"
import { communitiesURL, generatePropertyURL } from "@lib/urls"
import GGFXImage from "@components/elements/GGFXImage"
import { Link } from "gatsby"
import { slugify, stripHTML } from "@lib/utils"

const AreaguideCard = ({ areaguide, setSelectedAmenity, selectedAmenity }) => {
  if (!areaguide) return null

  const { title, tile_image, slug, ggfx_results, strapi_id, description, tags } = areaguide
  const formattedDescription = stripHTML(description?.data?.description)
  return (
    <div className="areaguide-card">
      <div className="img-section img-zoom">
        {/* <img src={tile_image?.url} alt={title} /> */}
        <Link to={`${communitiesURL + slug}/`}>
        <GGFXImage
          ImageSrc={tile_image}
          altText={title}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="area-guide.tile_image.tile_img"
          strapiID={strapi_id}
        /></Link>
        {tags?.strapi_json_value?.length > 0 &&
        <div className="cat-bk">
        {tags?.strapi_json_value?.map((item, i) => {
          return (<Link className="cat-tag" to={generatePropertyURL({
            searchType: "for-sale",
            buildingType: slugify(item),
          }) +
          "in-" +
          slug}>{item}</Link>)
        })}</div>}
      </div>
     
      <Link to={`${communitiesURL + slug}/`} className="title">
        {title}
      </Link>
      {formattedDescription && (
        <Link to={`${communitiesURL + slug}/`} className="description">{formattedDescription}</Link>
      )}
    </div>
  )
}

export default AreaguideCard
