import Pagination from "@components/Pagination/Pagination"
import TeamCardRounded from "@components/TeamCard/TeamCardRounded"
import CustomSelect from "@components/elements/CustomSelect/CustomSelect"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useTeams from "@hooks/dataHooks/useTeams"
import useDeviceMedia from "@hooks/useDeviceMedia"
import usePagination from "@hooks/usePagination"
import { useSessionStorage } from "@hooks/useSessionStorage"
import { capitalize, isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import "./TeamListing.scss"

const TeamListing = () => {
  const { isTablet } = useDeviceMedia()

  const allTeams = useTeams()
  const [teamsToShow, setTeamsToShow] = React.useState(allTeams)

  // get all categories
  const categories = allTeams?.reduce((acc, team) => {
    const teamCategories = team.category?.strapi_json_value
    if (!isEmpty(teamCategories)) {
      teamCategories.forEach((category) => {
        if (!acc.find((item) => item.value === category)) {
          acc.push({
            label: capitalize(category),
            value: category,
          })
        }
      })
    }
    return acc
  }, [])
  // get all categories

  if (!categories.find((item) => item.value === "Brokers")) {
    categories.push({
      label: "Brokers",
      value: "Brokers",
    })
  }

  const [selectedCategory, setSelectedCategory] = useSessionStorage(
    "team_category",
    categories?.[0]?.value
  )

  const [currentPage, setCurrentPage] = useSessionStorage("team_page", 1)

  const paginationProps = usePagination({
    items: teamsToShow,
    itemsPerPage: 32,
    customCurrentPage: currentPage,
    customSetCurrentPage: setCurrentPage,
  })

  // filter teams based on selected category
  React.useEffect(() => {
    if (selectedCategory === "Brokers") {
      const notManagementTeams = allTeams.filter(
        (team) => !team.category?.strapi_json_value?.includes("Management")
      )
      setTeamsToShow(notManagementTeams)
    } else {
      setTeamsToShow(
        allTeams.filter((team) => {
          const teamCategories = team.category?.strapi_json_value
          return teamCategories?.includes(selectedCategory)
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, allTeams])
  // filter teams based on selected category

  return (
    <div className="team-listing-wrap listing-wrap">
      <div className="category-section-wrap category-section">
        <Container className="category-section">
          {isTablet ? (
            <CustomSelect
              options={categories}
              placeholder="Select Category"
              value={
                categories.find((item) => item.value === selectedCategory) ||
                categories[0]
              }
              onChange={(option) => {
                paginationProps.setCurrentPage(1)
                setSelectedCategory(option.value)
              }}
            />
          ) : (
            <div className="category-tabs-section">
              <Tabs
                activeKey={selectedCategory}
                setActiveKey={setSelectedCategory}
                className="category-tabs"
                onChange={() => {
                  paginationProps.setCurrentPage(1)
                }}
              >
                {categories.map((category) => (
                  <Tab
                    eventKey={category.value}
                    title={category.label}
                    key={category.value}
                  >
                    {category.label}
                  </Tab>
                ))}
              </Tabs>
            </div>
          )}
        </Container>
      </div>
      <Container className="team-listing-container">
        <div className="team-category-select-section" />
        <div className="team-listing-section">
          {paginationProps?.currentItems?.map((team) => (
            <TeamCardRounded key={team?.id} team={team} />
          ))}
        </div>
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default TeamListing
