import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { teamsURL } from "@lib/urls"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React from "react"
import "./TeamCard.scss"

const TeamCardRounded = ({ team }) => {
  if (isEmpty(team)) return null

  const {
    name,
    designation,
    image,
    slug,
    ggfx_results,
    strapi_id,
    // category,
    // phone,
    languages,
    extra,
  } = team

  // const isManagement = category?.strapi_json_value?.includes("Management")

 
  return (
    <Animation
      wrapperClassName="team-card-wrap"
      className="team-card rounded-card"
    >
      <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
      {image?.url ? 
        <GGFXImage
          ImageSrc={image}
          altText={name}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="team.image.tile_img"
          strapiID={strapi_id}
        />
        : extra?.profile_image ?
        <img src={extra?.profile_image} alt={`${name} - ${process.env.GATSBY_SITE_NAME}`} />
        : <GGFXImage
          ImageSrc={image}
          altText={name}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="team.image.tile_img"
          strapiID={strapi_id}
        />}
      </Link>
      <p className="name">{name}</p>
      <p className="designation">{designation}</p>
      {languages?.strapi_json_value?.length > 0 && (
        <p className="languages">
          Languages: <span>{languages.strapi_json_value.join(", ")}</span>
        </p>
      )}
      {/* {!isManagement && (
        <div className="cta-section">
          <a href={`tel:${phone}`} className="button button-white">
            <PhoneIcon />
            <span>Call</span>
          </a>
          <a href={getWhatsAppURL(phone)} className="button button-green">
            <WhatsAppIcon outline2 />
            <span>WhatsApp</span>
          </a>
        </div>
      )} */}
    </Animation>
  )
}

export default TeamCardRounded
