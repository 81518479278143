import useDeviceMedia from "@hooks/useDeviceMedia"
import { useSubscriberStore } from "@lib/subscriberStore"
import { parseHTML } from "@lib/utils"
import { isEmpty } from "lodash"
import React, { useLayoutEffect } from "react"
import { Container } from "react-bootstrap"
import "./BannerListing.scss"

const BannerListing = ({ bannerData }) => {
  const {
    isMobile,
    isTabletOnly,
    isLargeScreenOnly,
    isXLargeScreenOnly,
    isDesktopOnly,
  } = useDeviceMedia()

  const { subscribers } = useSubscriberStore((state) => state)

  // set padding bottom for banner listing
  useLayoutEffect(() => {
    const bannerListingWrap = document.querySelector(".banner-listing-wrap")

    const categorySection = document.querySelector(".category-section")

    if (bannerListingWrap && categorySection) {
      const customTabsHeight = categorySection.offsetHeight

      let pad = 72
      let margin = 40

      if (isTabletOnly || isLargeScreenOnly) {
        pad = 96
        margin = 56
      } else if (isXLargeScreenOnly) {
        pad = 112
        margin = 72
      } else if (isDesktopOnly) {
        pad = 128
        margin = 88
      }

      bannerListingWrap.style.paddingBottom = `${
        Number(customTabsHeight) + pad
      }px`
      categorySection.style.top = `-${Number(customTabsHeight) + margin}px`
    }
  }, [
    isMobile,
    isDesktopOnly,
    isLargeScreenOnly,
    isTabletOnly,
    isXLargeScreenOnly,
    subscribers,
  ])

  if (isEmpty(bannerData)) return null

  const { title, description } = bannerData

  return (
    <div className="banner-listing-wrap">
      <Container className="banner-listing-container">
        <h1 className="title">{title}</h1>
        <div className="description">
          {parseHTML(description.data.description)}
        </div>
      </Container>
    </div>
  )
}

export default BannerListing
