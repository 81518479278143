import { graphql, useStaticQuery } from "gatsby"

const useAreaguides = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAreaGuide(
        filter: { publish: { eq: true } }
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          ...areaguideCardFragment
        }
      }
    }
  `)

  return data?.allStrapiAreaGuide?.nodes
}

export default useAreaguides
